import { useRef, useState } from "react";
import { getLayout, ILayout } from "../../layout/core";
import { SearchCriteria } from "./SearchCriteria";
import Orders, { OrdersType } from "./Orders";
// import IssuesSummary from "./IssuesSummary";
import { dDownType } from "../../types";
import { toast } from "react-hot-toast";
import OrderDate from "./OrderDate";
import { useIntlMsg } from "../../i18n";

function Order() {
  const [config, setConfig] = useState<ILayout>(getLayout());
  const updateData = (fieldsToUpdate: Partial<ILayout>) => setConfig({ ...config, ...fieldsToUpdate });

  const isStock = config.header.fixed.tabletAndMobile;
  const [isReseted, setIsReseted] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedOrdProc, setSelectedOrdProc] = useState<dDownType>(null); // selected order processor
  const [selectedStockLocation, setSelectedStockLocation] = useState<dDownType>(null); // selected stock location
  const [selectedStockLocGroup, setSelectedStockLocGroup] = useState<dDownType>(null); // selected stock location group
  // const [iSummary, setISummary] = useState<any[]>([]);

  const ordersRef = useRef<OrdersType>(null);
  const onResetFunction = () => ordersRef.current?.onReset();
  let { t } = useIntlMsg();

  const searchAction = () => {
    if (!selectedOrdProc?.value && !selectedStockLocation?.value) {
      toast.error(t("Please select order processor and stock location."));
    } else if (!selectedOrdProc?.value) {
      toast.error(t("Please select order processor."));
    } else if (!selectedStockLocation?.value) {
      toast.error(t("Please select stock location."));
    } else {
      setLoading(true); setIsReseted(false);
      ordersRef.current?.getOrders(1, 1, true);
    }
  };

  return (
    <>
      <section>
        {/*--- hide/bypass this feature for now, as we discussed ---*/}
        {/* {showAlert && <div className="alert alert-warning" role="alert">
          {alertMessage}
        </div>} */}
        <OrderDate isStock={isStock} />
       
        {/*--- hide/bypass this feature for now, as we discussed ---*/}
        {/* <IssuesSummary data={iSummary} /> */}
        <SearchCriteria
          loading={loading}
          onResetFunc={onResetFunction}
          isReseted={isReseted}
          setIsReseted={setIsReseted}
          updateData={updateData}
          config={config}
          isStock={isStock}
          handleSearchButton={searchAction}
          selectedOrdProc={selectedOrdProc}
          setSelectedOrdProc={setSelectedOrdProc}
          selectedStockLocation={selectedStockLocation}
          setSelectedStockLocation={setSelectedStockLocation}
          selectedStockLocGroup={selectedStockLocGroup}
          setSelectedStockLocGroup={setSelectedStockLocGroup}
        />
        <Orders
          ref={ordersRef}
          setLoading={setLoading}
          isReseted={isReseted}
          setIsReseted={setIsReseted}
          isStock={isStock}
          orderProcessor={selectedOrdProc?.value}
          stockLocationId={selectedStockLocation?.value}
        />
      </section>
    </>
  );
}

export default Order;
